import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import LangContext from '../../context/LangContext'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  static contextType = LangContext;

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {

    const { lang } = this.context;
    let content = lang === 'kr' ? content_kr : content_en;

    return (
      <Layout>
        <section className="section-contact">

          {/* <img src="../../img/contact/contact.png" alt="Contact" style={{ width: '30%', margin: '0 auto'}}/> */}
          <div className="contact-container-left" style={{ fontSize: "1.8rem", marginTop: "1.5em" }}>
            <b>{content.msp}</b><br /><br />
            <div style={{ paddingLeft: "1rem", fontSize: "1.2rem" }}>
              {content.msp_location}<br />
              {content.msp_location2}<br />
              {content.msp_location3}<br /><br /><br /><br />
              <b style={{fontSize: "1.5rem"}}>{content.msp_contact}</b><br />
              {content.msp_contact2}<br />
              {content.msp_contact3}<br /><br />
            </div>

          </div>
          <div className="contact-container-right" style={{ fontSize: "1.4rem" }}>
            <b>{content.location}</b><br /><br />
            <div style={{ paddingLeft: "1rem", fontSize: "1.2rem" }}>
              {content.location_line1}<br />
              {content.location_line2}<br />
              {content.location_line3}<br />
              {content.location_line4}<br /><br />
            </div>
            <div className="box">
              <img src="../../img/contact/map.jpg" alt="Map" />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

const content_kr = {

  // temp: "가. 개인정보 수집 항목\n\n" +
  //   "(주)신세계아이앤씨는 신청인의 다음의 개인정보 항목을 처리하고 있습니다.\n" +
  //   "1) 수집 항목 : 이메일, 성명, 회사정보, 연락처\n" +
  //   "2) 개인정보 이용 목적 : 회사는 귀하의 개인정보를 서비스 제공 및 이용에 관한 상담 업무 처리 목적으로 이용합니다.\n" +
  //   "3) 개인정보의 보유 및 이용 기간 : 회사는 귀하의 개인정보를 위 수집 및 이용 목적을 달성할 때까지 보유 및 이용합니다.\n" +
  //   "다만 관련 법령에 의해 보존이 필요한 경우 법정보유 기간 동안 이용자의 개인정보를 안전하게 보유합니다.\n" +
  //   "4) 동의거부권 및 불이익 : 귀하는 개인정보 수집에 대한 동의를 거부하실 수 있으며, 거부하시더라도 기본 서비스 이용에는 제한을 받지 않습니다.\n" +
  //   "다만, 필수로 수집되는 개인정보 동의 거부 시 일부 서비스가 제한될 수 있습니다.",

  // options_head: "* 문의유형을 선택해주세요.",

  // option_content: "With options",

  // name_placeholder: "이름",

  // contact_placeholder: "연락처",

  // email_placeholder: "이메일",

  // message_placeholder: "내용을 입력해주세요",
  msp: "MSP TEAM",
  msp_location: "주소 (04631)",
  msp_location2: "서울특별시 중구 소공로 48, 13층",
  msp_location3: "(회현동2가, 우리금융남산타워)",
  msp_contact: "상담문의",
  msp_contact2: "담당전화 : 02) 3397.1217",
  msp_contact3: "E-mail : jtb3552@shinsegae.com",

  location: "회사 위치",
  location_line1: "본사 (회현동)",
  location_line2: "주소 (04631) 서울특별시 중구 소공로 48, 10층",
  location_line3: "(회현동2가, 우리금융남산타워)",
  location_line4: "대표전화 : 02) 3397-1234",

}

const content_en = {

  msp: "MSP TEAM",
  msp_location: "Address (04631)",
  msp_location2: "48th and 13th floors of Sogong-ro, Jung-gu, Seoul",
  msp_location3: "(Hoehyeon-dong 2-ga, WooriFinance Namsan Tower)",
  msp_contact: "Consultation",
  msp_contact2: "PhoneNumber : 82-02) 3397.1217",
  msp_contact3: "E-mail : jtb3552@shinsegae.com",
  
  location: "Company Location",
  location_line1: "Head Office (Hoehyeon-dong)",
  location_line2: "Address (04631) 48th and 10th floors of Sogong-ro, Jung-gu, Seoul",
  location_line3: "(Hoehyeon-dong 2-ga, WooriFinance Namsan Tower)",
  location_line4: "Representative phone 82-02.3397.1234",
  
  // temp: "english-temp\n\n" +
  //   "(주)신세계아이앤씨는 신청인의 다음의 개인정보 항목을 처리하고 있습니다.\n" +
  //   "1) 수집 항목 : 이메일, 성명, 회사정보, 연락처\n" +
  //   "2) 개인정보 이용 목적 : 회사는 귀하의 개인정보를 서비스 제공 및 이용에 관한 상담 업무 처리 목적으로 이용합니다.\n" +
  //   "3) 개인정보의 보유 및 이용 기간 : 회사는 귀하의 개인정보를 위 수집 및 이용 목적을 달성할 때까지 보유 및 이용합니다.\n" +
  //   "다만 관련 법령에 의해 보존이 필요한 경우 법정보유 기간 동안 이용자의 개인정보를 안전하게 보유합니다.\n" +
  //   "4) 동의거부권 및 불이익 : 귀하는 개인정보 수집에 대한 동의를 거부하실 수 있으며, 거부하시더라도 기본 서비스 이용에는 제한을 받지 않습니다.\n" +
  //   "다만, 필수로 수집되는 개인정보 동의 거부 시 일부 서비스가 제한될 수 있습니다.",

  // options_head: "* Please select an inquiry type",

  // option_content: "With options",

  // name_placeholder: "Name",

  // contact_placeholder: "Contact",

  // email_placeholder: "Email",

  // message_placeholder: "Please enter your details",

}



// <div className="contact-container-left">
// <form
//   className="contact-form"
//   name="contact"
//   method="post"
//   action="/contact/thanks/"
//   data-netlify="true"
//   data-netlify-honeypot="bot-field"
//   onSubmit={this.handleSubmit}
// >
//   {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
//   <input type="hidden" name="form-name" value="contact" />
//   <div hidden>
//     <label>
//       Don’t fill this out:{' '}
//       <input name="bot-field" onChange={this.handleChange} />
//     </label>
//   </div>

//   <div className="field">
//     <div className="select">
//       <select>
//         <option>{content.options_head}</option>
//         <option>{content.option_content}</option>
//       </select>
//     </div>

//     <input
//       className="input"
//       type={'text'}
//       name={'name'}
//       onChange={this.handleChange}
//       id={'name'}
//       required={true}
//       placeholder={content.name_placeholder}
//     />
//   </div>

//   <div className="field">
//     <div className="control">
//       <input
//         className="input"
//         type={'text'}
//         name={'contact'}
//         onChange={this.handleChange}
//         id={'contact'}
//         required={true}
//         placeholder={content.contact_placeholder}
//       />

//       <input
//         className="input"
//         type={'email'}
//         name={'email'}
//         onChange={this.handleChange}
//         id={'email'}
//         required={true}
//         placeholder={content.email_placeholder}
//       />
//     </div>
//   </div>

//   <div className="field">
//     <label className="label" htmlFor={'message'}>
//     </label>
//     <div className="control">
//       <textarea
//         className="textarea"
//         name={'message'}
//         onChange={this.handleChange}
//         id={'message'}
//         required={true}
//         placeholder={content.message_placeholder}
//       />
//     </div>
//   </div>

//   <div className="field">
//     <label className="label" htmlFor={'consent'}>
//     </label>
//     <div className="control">
//       <textarea className="textarea" name={'consent'} readOnly={'readonly'} rows={'10'}
//         placeholder={content.temp} />
//     </div>
//   </div>

//   <div className="field">
//     <br />
//     <button className="button is-link" type="submit">
//       Send
//       </button>
//     <br />
//     <br />
//   </div>

// </form>
// </div>
